<template>
  <AdminLayout
    title="Dashboard"
  >
    <v-row>
      <v-col>
        <h2 class="text-center">Projects</h2>
        <ProjectList/>
      </v-col>
    </v-row>
  </AdminLayout>
</template>

<script>
import AdminLayout from '../../layouts/AdminLayout'
import ProjectList from '../../components/ProjectList'

export default {
  name: 'Dashboard',

  components: {
    AdminLayout,
    ProjectList,
  },
}
</script>
