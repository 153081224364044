<template>
  <div>
    <div class="d-flex mb-3">
      <v-text-field
        v-model="search.job_id"
        prepend-icon="mdi-magnify"
        placeholder="Job ID"
        hide-details
        clearable
        @input="searchProjects"
      ></v-text-field>
    </div>
    <v-simple-table class="project-list">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="min-td"></th>
          <th>Job ID</th>
          <th class="min-td">Owner</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="project in projects"
          :key="project.id"
        >
          <td>
            <v-icon :color="STATUS_COLORS[project.status]">
              {{ STATUS_ICONS[project.status] }}
            </v-icon>
          </td>
          <td>
            <router-link :to="`/project/${project.job_id}`">{{ project.job_id }}</router-link>
          </td>
          <td><span class="font-weight-bold">{{ project.user.name }}</span> {{ project.user.email }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { STATUS_COLORS, STATUS_ICONS } from '@/shared'

export default {
  name: 'ProjectList',

  data: () => ({
    search: {
      job_id: null,
      done: null,
    },
  }),

  created () {
    this.searchProjects()
  },

  computed: {
    STATUS_ICONS () {
      return STATUS_ICONS
    },
    STATUS_COLORS () {
      return STATUS_COLORS
    },
    ...mapState('dashboard', ['projects']),
  },

  methods: {
    ...mapActions('dashboard', ['fetchProjects']),
    searchProjects () {
      this.search.done = this.search.job_id ? null : 0
      this.fetchProjects(this.search)
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
.project-list {
  .min-td {
    width: 1px;
    white-space: nowrap;
  }

  a {
    display: flex;
    align-items: center;
    height: 100%;
    text-decoration: none;
    color: #000;
  }
}
</style>
